<template>
  <section class="plugins">

    <div class="plugins__container container">

      <div class="plugins__title">

        <h2 class="plugins__title--h2">
          Plugins
        </h2>

        <p class="plugins__title--p">
          para aumentar la productividad
        </p>

      </div>

      <div id="modulos" class="modulos plugins__slider">

        <div class="modulos__current">
          <div class="modulos__info">
            <h2 class="modulos__info--h2">
              {{ selectedItem.name }}
            </h2>
            <p class="modulos__info--p">
              {{ selectedItem.short_descript }}
            </p>
            <a class="modulos__info--a" href="#">
              <span>Ver más</span>
              <i class="ri-arrow-right-up-line"></i>
            </a>
          </div>

          <div class="modulos__arrows">
            <button @click="decrementIndex" class="modulos__arrows--left modulos__arrows--button">
              <i class="ri-arrow-left-s-line"></i>
            </button>
            <button @click="incrementIndex" class="modulos__arrows--right modulos__arrows--button">
              <i class="ri-arrow-right-s-line"></i>
            </button>
          </div>
        </div>

        <div class="modulos__featured">
          <div class="modulos__featured--box">
            <img class="modulos__featured--img" :src="'assets/img/plugins/' + selectedItem.image"
              alt="featured image slider">
          </div>
        </div>

        <div class="modulos__slider">
          <div v-for="(plugin, index) in plugins" :key="index"
            :class="['modulos__item', (index === selectedIndex) ? 'modulos__item--active' : '']">

            <div class="modulos__container">
              <div class="modulos__info">
                <h3 class="modulos__title">
                  {{ plugin.name }}
                </h3>
              </div>
              <div class="modulos__featured--item">
                <img class="modulos__img--item" :src="'assets/img/plugins/' + plugin.image"
                  alt="featured imagen item slider">
              </div>
            </div>
            <!-- 
                        <div class="modulos__time">
                            <div class="modulos__baseline" :style="{ width: progress + '%' }"></div>
                        </div>
                       -->
          </div>
        </div>

      </div>
    </div>

  </section>
</template>

<script lang="ts" setup>
import Plugins from '@/entities/Plugins';
import PluginsService from '@/services/PluginsService';
import { computed, onMounted, ref, Ref } from 'vue';

const pluginsService: Ref<PluginsService> = ref(new PluginsService())
const plugins: Ref<Plugins[]> = ref([])
let selectedIndex: Ref<number> = ref(0)
const isMounted = ref(false)
//const progress = ref(0);
//let intervalId: number | null = null;

onMounted(async () => {
  await getPlugins()
  getItemByIndex(0)
  //startProgressBar(0)
  isMounted.value = true
})

const getPlugins = async () => {
  plugins.value = await pluginsService.value.getPlugins()
}

const getItemByIndex = (index: number): Plugins | undefined => {
  const slider = document.querySelector('.modulos__slider') as Element
  if (slider) {
    const items = Array.from(slider.querySelectorAll('.modulos__item')) as HTMLDivElement[]
    if (index >= 0 && index < items.length) {
      selectedIndex.value = index
      slider.scrollTo({ left: items[index].offsetLeft, behavior: 'smooth' });
      return plugins.value[index]
    }
  }
  return undefined
}

const selectedItem = computed((): Plugins => {
  if (isMounted.value && plugins.value.length > 0) {
    return plugins.value[selectedIndex.value]
  }
  return {} as Plugins
})

const incrementIndex = () => {
  const slider = document.querySelector('.modulos__slider') as Element
  if (slider) {
    const items = Array.from(slider.querySelectorAll('.modulos__item')) as HTMLDivElement[]
    selectedIndex.value = (selectedIndex.value + 1) % plugins.value.length
    slider.scrollTo({ left: items[selectedIndex.value].offsetLeft, behavior: 'smooth' });
  }
};

const decrementIndex = () => {
  const slider = document.querySelector('.modulos__slider') as Element
  const items = Array.from(slider.querySelectorAll('.modulos__item')) as HTMLDivElement[]
  selectedIndex.value = (selectedIndex.value - 1 + plugins.value.length) % plugins.value.length;
  slider.scrollTo({ left: items[selectedIndex.value].offsetLeft, behavior: 'smooth' });
  //stopProgressBar()
  //startProgressBar(0)
};

/* const startProgressBar = (initVal: number) => {
    progress.value = initVal
    const duration = 5000
    const interval = 50
    const step = (interval / duration) * 100

    intervalId = window.setInterval(() => {
        progress.value += step
        if (progress.value >= 100) {
            progress.value = 100
            stopProgressBar()
            onProgressComplete()
        }
    }, interval)
}; */

/* const stopProgressBar = () => {
    if (intervalId !== null) {
        clearInterval(intervalId);
        intervalId = null; // Reset the interval ID
    }
};

const onProgressComplete = () => {
    incrementIndex()
}; */

</script>
