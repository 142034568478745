<template>
  <div class="mod-core">

    <div class="mod-core__logo">
      <img class="mod-core__logo--logo" src="../../assets/icons/modules/exalab-core.svg" alt="">
    </div>

    <div class="mod-core__container">

      <svg class="mod-core__container--arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
        fill="currentColor">
        <path
          d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z">
        </path>
      </svg>

      <RouterLink v-for="modulo in modulos" :key="modulo.id" :to="{ name: 'services', params: { id: modulo.id } }"
        :class="['mod-core__container--a', (modulo.id == id) ? 'active' : '']">
        <i :class="['mod-hero__module--i', modulo.icon]"></i>
        <span>{{ modulo.short_name }}</span>
      </RouterLink>

    </div>
  </div>
</template>

<script lang="ts" setup>
import Modulos from '@/entities/Modulos';
import ModulosService from '@/services/ModulosService';
import { defineProps, onMounted, Ref, ref } from 'vue'
const modulosService: Ref<ModulosService> = ref(new ModulosService())
const modulos: Ref<Modulos[]> = ref([])

const props = defineProps<{
  id: number
}>();

onMounted(() => {
  getModulos()
  floatingCore()
})

const getModulos = async () => {
  modulos.value = await modulosService.value.getModulos()
}

const floatingCore = () => {
  const arrow = document.querySelector('.mod-core__container--arrow');
  const modCore = document.querySelector('.mod-core');

  if (arrow && modCore) {
    const checkScreenSize = () => {
      if (window.innerWidth <= 767) {
        modCore.classList.add('hide');
      } else {
        modCore.classList.remove('hide');
      }
    }

    checkScreenSize();

    arrow.addEventListener('click', function () {
      modCore.classList.toggle('hide');
    });

    window.addEventListener('resize', checkScreenSize);
  } else {
    console.warn('El ícono de flecha o el contenedor no se encontraron en el DOM.');
  }
}
</script>
