import Contacto from "@/entities/Contacto";
import { API_URL } from "@/lib/api";

export default class ContactService {
  public async addContact(contacto: Contacto): Promise<Contacto> {
    const response = await fetch(`${API_URL}/addContact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contacto),
    });

    const result = await response.json();
    if (!response.ok || !result.success) {
      throw new Error(result.message || "Error al agregar el contacto.");
    }

    return result.data;
  }
}
