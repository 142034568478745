import Testimonials from "@/entities/Testimonials";
import { API_URL } from "@/lib/api";

export default class TestimonialsService {
  public async getTestimonials(): Promise<Testimonials[]> {
    const response = await fetch(`${API_URL}/getTestimonials`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    if (!response.ok || !result.success) {
      throw new Error(result.message || "Error al obtener los testimonios.");
    }

    return result.data;
  }
}
