import Plans from "@/entities/Plans";
import { API_URL } from "@/lib/api";

export default class PlansService {
  public async getPlans(): Promise<Plans[]> {
    const response = await fetch(`${API_URL}/getPlans`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    if (!response.ok || !result.success) {
      throw new Error(result.message || "Error al obtener los planes.");
    }

    return result.data;
  }
}
