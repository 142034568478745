<template>
  <div class="mod-hero">
    <div class="mod-hero__container container">

      <div class="mod-hero__info">
        <div class="mod-hero__icon" style="color:rgb(50, 231, 186)">
          <i :class="['mod-hero__icon--i', modulo.icon]"></i>
        </div>
        <p class="mod-hero__tag">
          Módulo
        </p>
        <h1 class="mod-hero__title">
          {{ modulo.name }}
        </h1>
        <p class="mod-hero__subtitle">
          {{ modulo.short_descript }}
        </p>
        <a href="#more" class="mod-hero__showmore">
          <i class="mod-hero__showmore--i ri-arrow-down-s-line"></i>
        </a>
      </div>


      <div class="mod-hero__core">

        <RouterLink v-for="(m, index) in modulos" :key="index" :to="{ name: 'services', params: { id: m.id } }"
          :class="['mod-hero__module', m.id === modulo.id ? 'active' : '']">
          <i :class="['mod-hero__module--i', m.icon]"></i>
          <p class="mod-hero__module--p">
            {{ m.name }}
          </p>
        </RouterLink>

      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Modulos from '@/entities/Modulos';
import ModulosService from '@/services/ModulosService';
import { defineProps, onMounted, Ref, ref } from 'vue'

const modulosService: Ref<ModulosService> = ref(new ModulosService())
const modulos: Ref<Modulos[]> = ref([])

const props = defineProps<{
  modulo: Modulos,
}>();

onMounted(async () => {
  await getModulos()
})

const getModulos = async () => {
  modulos.value = await modulosService.value.getModulos()
}

</script>
