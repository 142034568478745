<template>
  <section class="hero">

    <div class="hero__container container">

      <div class="hero__info">
        <h2 class="hero__info--h2">Exalab 3</h2>
        <h1 class="hero__info--h1">Sistema de exámenes laborales<br class="br-escritorio"> preocupacionales y
          ocupacionales</h1>
        <p class="hero__info--p">El sistema más <strong>rápido</strong> para tu clínica</p>
        <div class="hero__info--cta hero__cta">
          <RouterLink :to="{ name: 'price' }" class="hero__cta--link">
            Ver Planes
            <i class="ri-arrow-right-up-line"></i>
          </RouterLink>
          <a href="#" class="hero__cta--play">
            <i class="ri-play-large-fill"></i>
          </a>
        </div>
      </div>

      <div class="hero__picture">
        <img class="hero__picture--img" src="../../assets/img/ilustracion-referencia.png" alt="#">
      </div>

    </div>
  </section>
</template>
