import Policy from "@/entities/Policy";
import { API_URL } from "@/lib/api";

export default class LegalService {
  public async getPolicies(): Promise<Policy[]> {
    const response = await fetch(`${API_URL}/getPolicies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    if (!response.ok || !result.success) {
      throw new Error(result.message || "Error al obtener las políticas.");
    }

    return result.data;
  }

  public async getPolicyByLinkName(link_name: string): Promise<Policy> {
    const response = await fetch(
      `${API_URL}/getPolicyByLinkName?link_name=${link_name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const result = await response.json();

    if (!response.ok || !result.success || result.data.length === 0) {
      throw new Error(
        result.message ||
          `Error al obtener la política con link_name ${link_name}.`
      );
    }

    return result.data[0];
  }
}
