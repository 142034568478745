<template>
  <div v-for="modulo in moduloDetails" :key="modulo.id" :class="['mod-featured', modulo.mirror ? 'mirror' : '']">
    <div :class="['mod-featured__img', 'entry2']">
      <img class="mod-featured__img--img" :src="'/assets/img/services/details/' + modulo.image">
    </div>
    <div class="mod-featured__info">
      <span class="mod-featured__info--span">
        Característica
      </span>
      <h2 class="mod-featured__info--h2">
        {{ modulo.title }}
      </h2>
      <p class="mod-featured__info--p">
        {{ modulo.descript }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, defineProps, ref, watch } from 'vue';
import ModulosService from '@/services/ModulosService';
import ModuloDetail from '@/entities/ModulosDetails';

const modulosService = new ModulosService();
const moduloDetails = ref<ModuloDetail[]>([]);
const props = defineProps<{ id: number }>();

onMounted(async () => {
  try {
    const details = await modulosService.getDetalleModuloById(props.id);
    moduloDetails.value = details;
  } catch (error) {
    console.error('Error fetching module details:', error);
  }
});

watch(() => props.id, async (newId) => {
  try {
    const details = await modulosService.getDetalleModuloById(newId);
    moduloDetails.value = details;
  } catch (error) {
    console.error('Error fetching new module details:', error);
  }
});
</script>
