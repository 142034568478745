import Services from "@/entities/Services";
import { API_URL } from "@/lib/api";

export default class ServiciosService {
  public async getServices(): Promise<Services[]> {
    const response = await fetch(`${API_URL}/getServices`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    if (!response.ok || !result.success) {
      throw new Error(result.message || "Error al obtener los servicios.");
    }

    return result.data;
  }
}
