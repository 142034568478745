<template>
  <section class="contact">

    <div class="contact__container container">

      <div class="contact__title">
        <h2 class="contact__title--h2">Contacto</h2>
        <p class="contact__title--p">Si aún tienes dudas contáctanos</p>
      </div>

      <form @submit="onSubmit" class="form">

        <div class="form__group">
          <label for="nombre" class="form__group--label">Ingrese su nombre <span>*</span></label>
          <div class="form__group--box">
            <i class="form__group--i ri-user-line"></i>
            <input v-model="name" v-bind="nameAttrs" class="form__group--input" type="text"
              placeholder="Ingrese su Nombre">
          </div>

          <div :class="['form__group--message', (errors.name ? 'active' : '')]">
            <p class="form__group--p danger">{{ errors.name }}</p>
          </div>
        </div>

        <div class="form__group">
          <label for="nombre-centro" class="form__group--label">Ingrese el nombre de su centro médico
            <span>*</span></label>
          <div class="form__group--box">
            <i class="form__group--i ri-hospital-line"></i>
            <input v-model="medical_center" v-bind="medical_centerAttrs" class="form__group--input" type="text"
              placeholder="Nombre del Centro Médico">
          </div>

          <div :class="['form__group--message', (errors.medical_center ? 'active' : '')]">
            <p class="form__group--p danger">{{ errors.medical_center }}</p>
          </div>
        </div>

        <div class="form__group">
          <label for="correo" class="form__group--label">Ingrese su correo <span>*</span></label>
          <div class="form__group--box">
            <i class="form__group--i ri-mail-line"></i>
            <input v-model="email" v-bind="emailAttrs" class="form__group--input" type="text"
              placeholder="nombre@correo.com">
          </div>

          <div :class="['form__group--message', (errors.email ? 'active' : '')]">
            <p class="form__group--p danger">{{ errors.email }}</p>
          </div>
        </div>

        <div class="form__group">
          <label for="correo" class="form__group--label">Mensaje</label>
          <div class="form__group--box">
            <i class="form__group--i ri-message-2-line"></i>
            <textarea v-model="descript" v-bind="descriptAttrs" class="form__group--textarea"
              placeholder="Escriba su mensaje aquí"></textarea>
          </div>

          <div :class="['form__group--message', (errors.descript ? 'active' : '')]">
            <p class="form__group--p danger">{{ errors.descript }}</p>
          </div>
        </div>

        <div class="form__group">
          <label for="servicio" class="form__group--label">Seleccione el servicio que le interesa <span>*</span></label>
          <div class="form__group--box">
            <i class="form__group--i ri-list-check"></i>
            <select v-model="service_id" v-bind="service_idAttrs" class="form__group--select">
              <option v-for="servicio in servicios" :key="servicio.id" :value="servicio.id">{{ servicio.name }}</option>
            </select>
          </div>

          <div :class="['form__group--message', (errors.service_id ? 'active' : '')]">
            <p class="form__group--p danger">{{ errors.service_id }}</p>
          </div>
        </div>


        <div class="form__group">
          <button type="submit" class="form__group--submit">
            Solicitar Contacto
            <i class="ri-mail-send-line"></i>
          </button>
        </div>

        <!-- para mostrar class="active" -->
        <!--para color rojo class="danger"-->
        <!--para color verde class="success"-->
        <div class="form__alert active success">
          <p class="form__alert--p">Alerta de éxito o error</p>
        </div>

      </form>

    </div>

  </section>
</template>
<script lang="ts" setup>
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import Contacto from '@/entities/Contacto';
import Services from '@/entities/Services';
import ServiciosService from '@/services/ServiciosService';
import { onMounted, reactive, Ref, ref } from 'vue';
import ContactService from '@/services/ContactService';
//import { EmailService } from '@/services/EmailService';

const serviciosServide = ref(new ServiciosService())
const servicios: Ref<Services[]> = ref([])
const contactService = ref(new ContactService())
//const emailService = ref(new EmailService())

const { errors, handleSubmit, defineField } = useForm({
  validationSchema: yup.object({
    email: yup.string().email('El email no es válido').required('El email es requerido'),
    descript: yup.string().required('La descripción es requerida'),
    medical_center: yup.string().required('El nombre del Centro Médico es requerido'),
    name: yup.string().required('Su Nombre es requerido'),
    service_id: yup.number().required('Debe Seleccionar el servicio').min(1, 'El ID de servicio debe ser mayor a 0'),
  }), initialValues: reactive({
    email: '',
    descript: '',
    medical_center: '',
    name: '',
    service_id: 1,
  }),
});

const [email, emailAttrs] = defineField('email');
const [descript, descriptAttrs] = defineField('descript');
const [medical_center, medical_centerAttrs] = defineField('medical_center');
const [name, nameAttrs] = defineField('name');
const [service_id, service_idAttrs] = defineField('service_id');

const onSubmit = handleSubmit(async (values: Contacto, { resetForm }) => {
  try {
    const response = await contactService.value.addContact(values);

    let email = {
      from: values.email,
      to: 'angel.jhon@gmail.com',
      subject: values.name,
      text: values.descript,
    };

    resetForm();
  } catch (error) {
    console.error('Error al enviar el formulario:', error);
  }
});

onMounted(async () => {
  await getServices()
})

const getServices = async () => {
  try {
    servicios.value = await serviciosServide.value.getServices();
  } catch (error) {
    console.error('Error al obtener servicios:', error);
  }
};

</script>
