import Plugins from "@/entities/Plugins";
import { API_URL } from "@/lib/api";

export default class PluginsService {
  public async getPlugins(): Promise<Plugins[]> {
    const response = await fetch(`${API_URL}/getPlugins`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    if (!response.ok || !result.success) {
      throw new Error(result.message || "Error al obtener los servicios.");
    }

    return result.data;
  }
}
