<template>
  <TopBar></TopBar>
  <main class="main">
    <HeroSection :policy="policy"></HeroSection>
    <BodySection :policy="policy"></BodySection>
  </main>
  <FooterBar />
</template>

<script lang="ts" setup>
import HeroSection from '@/components/legal/HeroSection.vue';
import TopBar from '../components/TopBar.vue';
import FooterBar from '@/components/FooterBar.vue';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import BodySection from '@/components/legal/BodySection.vue';
import LegalService from '@/services/LegalService';
import Policy from '@/entities/Policy';

const route = useRoute();
const legalService = ref(new LegalService());
const policy = ref<Policy>({ name: '', short_descript: '', descript: '', link_name: '' });
const linkName = route.params.linkName?.toString() || '';

onMounted(async () => {
  await loadPolicy();
});

const loadPolicy = async () => {
  try {
    if (!linkName) {
      throw new Error('El «link_name» es requerido');
    }
    const fetchedPolicy = await legalService.value.getPolicyByLinkName(linkName);
    policy.value = fetchedPolicy || { name: '', short_descript: '', descript: '', link_name: '' };
  } catch (error) {
    console.error('Error fetching policy:', error);
  }
};
</script>
