import Modulos from "@/entities/Modulos";
import ModulosDetails from "@/entities/ModulosDetails";
import { API_URL } from "@/lib/api";

export default class ModulosService {
  public async getModulos(): Promise<Modulos[]> {
    const response = await fetch(`${API_URL}/getModulos`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    if (!response.ok || !result.success) {
      throw new Error(result.message || "Error al obtener los módulos.");
    }

    return result.data;
  }

  public async getModuloById(id: number): Promise<Modulos> {
    const response = await fetch(`${API_URL}/getModuloById?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    if (!response.ok || !result.success) {
      throw new Error(
        result.message || `Error al obtener el módulo con ID ${id}.`
      );
    }

    return result.data[0];
  }

  public async getDetalleModuloById(id: number): Promise<ModulosDetails[]> {
    const response = await fetch(`${API_URL}/getDetalleModuloById?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    if (!response.ok || !result.success) {
      throw new Error(
        result.message || `Error al obtener el detalle del módulo con ID ${id}.`
      );
    }

    return result.data;
  }
}
