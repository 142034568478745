<template>
  <TopBar></TopBar>
  <main class="main">
    <HeroSection :modulo="modulo"></HeroSection>
    <VideoSection :modulo="modulo"></VideoSection>
    <FeaturedSection :id="serviceId"></FeaturedSection>
    <CoreSection :id="serviceId"></CoreSection>
  </main>
  <FooterBar />
</template>

<script lang="ts" setup>
import TopBar from '../components/TopBar.vue'
import FooterBar from '@/components/FooterBar.vue';
import CoreSection from '@/components/services/CoreSection.vue';
import FeaturedSection from '@/components/services/FeaturedSection.vue';
import HeroSection from '@/components/services/HeroSection.vue'
import VideoSection from '@/components/services/VideoSection.vue';
import Modulos from '@/entities/Modulos';
import ModulosService from '@/services/ModulosService';
import { onMounted, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute()
const modulosService: Ref<ModulosService> = ref(new ModulosService())
const modulo: Ref<Modulos> = ref<Modulos>({ name: '', image: '', index: 0, short_descript: '' })
let serviceId: Ref<number> = ref<number>(0)

onMounted(async () => {
  serviceId.value = parseInt(Array.isArray(route.params.id) ? route.params.id[0] : route.params.id)
  await getModulo(serviceId.value)
})

const getModulo = async (id: number) => {
  modulo.value = await modulosService.value.getModuloById(id)
  console.log('Modulo cargado:', modulo.value)
}

watch(() => route.params.id, async (newId) => {
  console.log('Nuevo ID:', newId);
  serviceId.value = parseInt(Array.isArray(newId) ? newId[0] : newId)
  await getModulo(serviceId.value)
});
</script>
